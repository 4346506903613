import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import Link from '../link/internal-link';
import { TopCommentIcon } from '../icons/top-comment-icon';
import IconTooltip from '../icon-tooltip';
import styles from './top-comment-icon-link.scss';
import { SolvedIcon } from '../icons/solved-icon';
import { isQACategoriesExperimentEnabled } from '../../selectors/experiments-selectors';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_MARK_AS_SOLVED } from '../../constants/experiments';

const TopCommentIconLink = ({
  t,
  toolTipClassName,
  linkComponent: LinkComponent,
  resolveCommentDeepLinkUrl,
  topCommentId,
  isMarkAsSolvedExperimentEnabled,
  isQACategoriesExperimentEnabled,
}) => (
  <IconTooltip
    text={t(isMarkAsSolvedExperimentEnabled ? 'comment.solved' : 'comment.top-comment')}
    className={toolTipClassName}
  >
    <LinkComponent
      dynamicLocationResolver={() => resolveCommentDeepLinkUrl(topCommentId)}
      className={styles.link}
      to={`/main/comment/${topCommentId}`}
      aria-label={t(isMarkAsSolvedExperimentEnabled ? 'comment.solved.label' : 'top-comment.label')}
    >
      {isMarkAsSolvedExperimentEnabled ? (
        <SolvedIcon
          className={isQACategoriesExperimentEnabled ? 'button-fill' : 'icon-secondary-fill'}
          isColored={!isQACategoriesExperimentEnabled}
        />
      ) : (
        <TopCommentIcon className="icon-secondary-fill" />
      )}
    </LinkComponent>
  </IconTooltip>
);

TopCommentIconLink.propTypes = {
  topCommentId: PropTypes.string,
  toolTipClassName: PropTypes.string,
  linkComponent: PropTypes.any,
  resolveCommentDeepLinkUrl: PropTypes.func,
  t: PropTypes.func,
  isQACategoriesExperimentEnabled: PropTypes.bool,
};

TopCommentIconLink.defaultProps = {
  linkComponent: Link,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  resolveCommentDeepLinkUrl: actions.resolveCommentDeepLinkUrlPromisified,
  isQACategoriesExperimentEnabled: isQACategoriesExperimentEnabled(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withExperiment({ isMarkAsSolvedExperimentEnabled: EXPERIMENT_MARK_AS_SOLVED }),
)(TopCommentIconLink);
